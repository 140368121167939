import "./DarkMode.scss";
import {ChangeEventHandler} from "react";

const setDark = () => {
    localStorage.setItem("theme", "dark");
    document.documentElement.setAttribute("data-theme", "dark");
};

const setLight = () => {
    localStorage.setItem("theme", "light");
    document.documentElement.setAttribute("data-theme", "light");
};

const storedTheme = localStorage.getItem("theme");

const prefersDark =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

const defaultDark =
    storedTheme === "dark" || (storedTheme === null && prefersDark);

if (defaultDark) {
    setDark();
}

const toggleTheme: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (e.target.checked) {
        setDark();
    } else {
        setLight();
    }
};

const DarkMode = () => {
    return (
        <div className="toggle-theme-wrapper">
            <label className="toggle-theme" htmlFor="checkbox">
                <input
                    type="checkbox"
                    id="checkbox"
                    onChange={toggleTheme}
                    defaultChecked={defaultDark}
                />
                <div className="slider round"></div>
            </label>

            {/*<label className="text" htmlFor="switchDark">*/}
            {/*    <h3>Dark Mode</h3>*/}
            {/*    <p>Browsing in night mode</p>*/}
            {/*</label>*/}
            {/*<label className="switch_toggle toggle_lg theme-switch" htmlFor="switchDark">*/}
            {/*    <input type="checkbox" className="switchDarkMode theme-switch" id="switchDark"*/}
            {/*           aria-describedby="switchDark"/>*/}
            {/*        <span className="handle"></span>*/}
            {/*</label>*/}


        </div>
    );
};

export default DarkMode;
