import {Swiper, SwiperSlide} from 'swiper/react';
import axios from "axios";
import {useEffect, useState} from "react";
import {ToastInfo} from "../Toast/info";

import "swiper/css";
import "swiper/css/navigation"

import SwiperCore, {
    Navigation
} from 'swiper';

SwiperCore.use([Navigation]);

type Name = {
    name_title: string;
    name_domain: string;
    name_pic: string;
    name_price: string;
    name_likes: string;
    owner_name: string;
    owner_avatar: string;
}

export const NameSwiper = () => {
    const [names, setNames] = useState<Name[] | null>([]);

    useEffect(() => {
        // const url = 'http://192.168.1.4:8080/api/v1/topnames';
        const url = 'https://vk.rocket.name/api/v1/topnames';
        axios.get(url).then((response) => {
            // console.log(response.data);
            setNames(response.data)
        })
    }, []);

    let [checked, setChecked] = useState(false);


    return (
        <>

            <section className="unSwiper-cards margin-t-20">
                <div className="content-cards-NFTs margin-t-20">
                    <Swiper
                        spaceBetween={0}
                        centeredSlides={false}
                        slidesPerView={1.1}
                        className={'cardGradual'}
                        navigation={false}
                        modules={[Navigation]}
                        // wrapperClass={'swiper-wrapper'}

                    >
                        {names ? names.map((names,index) => {
                            return (
                                <SwiperSlide key={`${names.name_domain}-${index}`}>
                                    <div className="swiper-slide">
                                        <div className="item-card-gradual">
                                            <div
                                                className="head-card d-flex justify-content-between align-items-center">
                                                <div className="creator-name">
                                                    <div className="image-user">
                                                        <picture>
                                                            <source srcSet={names.owner_avatar}
                                                                    type="image/webp"/>
                                                            <img className="img-avatar"
                                                                 src={"assets/images/avatar/profile.jpg"}
                                                                 alt="NFT"/>
                                                        </picture>
                                                        <div className="icon">
                                                            <i className="ri-checkbox-circle-fill"></i>
                                                        </div>
                                                    </div>
                                                    <h3>{names.owner_name}</h3>
                                                </div>
                                                <div className="btn-like-click">
                                                    <div className="btnLike">
                                                        <input type="checkbox"
                                                               defaultChecked={checked || false}
                                                               onClick={() => setChecked(!checked)}
                                                        />
                                                        <span className="count-likes">{names.name_likes}</span>
                                                        <i className="ri-heart-3-line"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <a href="/name-details" className="body-card py-0">
                                                <div className="cover-nft">
                                                    <picture>
                                                        {/*<source srcSet={"assets/images/names/3dshop.jpg"} type="image/jpg"/>*/}
                                                        <img className="img-cover swiper-lazy"
                                                             src={names.name_pic}
                                                             alt="NFT"/>
                                                    </picture>
                                                    <div className="icon-type">
                                                        <i className="ri-vidicon-line"></i>
                                                    </div>
                                                    <div className="countdown-time">
                                                        <span>08H 38M 16S</span>
                                                    </div>
                                                </div>
                                                <div className="title-card-nft">
                                                    <div className="side-one">
                                                        <h2>{names.name_title}</h2>
                                                        <p>Домен: {names.name_domain}</p>
                                                    </div>
                                                    <div className="side-other">
                                                        <span className="no-sales">3 for sale</span>
                                                    </div>
                                                </div>

                                            </a>
                                            <div className="footer-card">
                                                <div className="starting-bad">
                                                    <h4>{names.name_price}</h4>
                                                    <span>Начальная ставка</span>
                                                </div>
                                                <button type="button"
                                                        className="btn btn-md-size bg-primary text-white rounded-pill"
                                                        onClick={ToastInfo}>
                                                    Сделать ставку
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            )
                        }) : null}

                    </Swiper>

                </div>
            </section>

        </>
    )
}