import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Toast from 'react-bootstrap/Toast';

export const TestToast = () => {
    const [showA, setShowA] = useState(true);
    const [showB, setShowB] = useState(true);

    const toggleShowA = () => setShowA(!showA);
    const toggleShowB = () => setShowB(!showB);

    return (
        <div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center place__top w-100">
            <div id="liveToastOne" className="toast --shadow" role="alert" aria-live="assertive" aria-atomic="true"
                 data-bs-autohide="true" data-bs-delay="3000">
                <div className="toast-body">
                    <div className="icon color-blue">
                        <i className="ri-information-fill"></i>
                    </div>
                    <div className="content">
                        <div className="display__text">
                            <p>Update your profile.
                            </p>
                            <a href="page-edit-profile.html" className="display-link color-blue">Go to Settings</a>
                        </div>
                    </div>
                    <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close">
                        <i className="ri-close-fill"></i>
                    </button>
                </div>
            </div>
        </div>
    );
}

// export default TestToast;