import React from 'react';
import {Header} from "./components/Header/Header";
import {SideBarAdmin} from "./components/SideBar/SideBar-admin";
import {NameSwiper} from "./components/NameSwiper/NameSwiper";
import {BestSeller} from "./components/BestSeller/BestSeller";
import {Footer} from "./components/Footer/Footer";
// import TestSwiper from "./components/TestSwiper/TestSwiper";
// import {ToggleSwitch} from "./components/ui/ToggleSwitch";
import {TestToast} from "./components/Toast/test";

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import 'font-awesome/css/font-awesome.min.css';
import 'remixicon/fonts/remixicon.css'

function App() {
    return (
        <>
            <div className="wrapper">
                <div id="content">
                    <Header/>
                    <NameSwiper/>
                    <BestSeller/>
                </div>
                <Footer/>
                <SideBarAdmin/>
                <TestToast/>

            </div>

        </>
    );
}

export default App;