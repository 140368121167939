import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter, Route, Routes} from 'react-router-dom';

import './assets/scss/style.scss'
import {Profile} from "./views/Profile";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}>
                </Route>
                <Route path="/profile" element={<Profile/>}>
                </Route>
                <Route path="/">
                    {/*<PageNotFound />*/}
                </Route>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);