import {Header} from "../components/Header/Header";

import {Footer} from "../components/Footer/Footer";
import {SideBarAdmin} from "../components/SideBar/SideBar-admin";
import React from "react";

export const Profile = () => {
    return (
        <>
            <div className="wrapper">
                <div id="content">
                    Profile
                </div>
                <Footer/>
                <SideBarAdmin/>
            </div>
        </>
    )
}