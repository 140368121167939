import Toast from 'react-bootstrap/Toast';
import {useState} from "react";

export const ToastInfo = () => {
    const [showA, setShowA] = useState(true);

    const toggleShowA = () => setShowA(!showA);

    return (
        // <div aria-live="polite" aria-atomic="true" className="d-flex justify-content-center place__top w-100">
        //     <div id="liveToastOne" className="toast --shadow" role="alert" aria-live="assertive" aria-atomic="true"
        //          data-bs-autohide="true" data-bs-delay="3000">
        //         <div className="toast-body">
        //             <div className="icon color-blue">
        //                 <i className="ri-information-fill"></i>
        //             </div>
        //             <div className="content">
        //                 <div className="display__text">
        //                     <p>Update your profile.
        //                     </p>
        //                     <a href="/profile" className="display-link color-blue">Go to Settings</a>
        //                 </div>
        //             </div>
        //             <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close">
        //                 <i className="ri-close-fill"></i>
        //             </button>
        //         </div>
        //     </div>
        // </div>
        <Toast show={showA} onClose={toggleShowA}>
            <Toast.Header>
                <strong className="me-auto">Bootstrap</strong>
                <small>11 mins ago</small>
            </Toast.Header>
            <Toast.Body>Woohoo, you're reading this text in a Toast!</Toast.Body>
        </Toast>
    )
}