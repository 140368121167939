export const Header = () => {
    return (
        <>
        <header className="default heade-sticky">
            <a href="/">
                <div className="un-item-logo">
                    <img className="logo-img light-mode" src={"assets/images/rocketname-logo-2.svg"} alt=""/>
                    <img className="logo-img dark-mode" src={"assets/images/rocketname-logo-2.svg"} alt=""/>
                </div>
            </a>
            <div className="un-block-right">
                <div className="un-notification">
                    <a href="/activity" aria-label="activity">
                        <i className="ri-notification-line"></i>
                    </a>
                    <span className="bull-activity"></span>
                </div>
                <div className="un-user-profile">
                    <a href="/profile" aria-label="profile">
                        <picture>
                            <source srcSet={"assets/images/avatar/profile.jpg"} type="image/webp"/>
                            <img className="img-avatar" src={"assets/images/avatar/profile.jpg"} alt=""/>
                        </picture>
                    </a>
                </div>

                <div className="menu-sidebar">
                    <button type="button" name="sidebarMenu" aria-label="sidebarMenu" className="btn"
                            data-bs-toggle="modal" data-bs-target="#mdllSidebar-connected">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="9.3" viewBox="0 0 19 9.3">
                            <g id="Group_8081" data-name="Group 8081" transform="translate(-329 -37)">
                                <rect id="Rectangle_3986" data-name="Rectangle 3986" width="19" height="2.3"
                                      rx="1.15" transform="translate(329 37)" fill="#222032"/>
                                <rect id="Rectangle_3987" data-name="Rectangle 3987" width="19" height="2.3"
                                      rx="1.15" transform="translate(329 44)" fill="#222032"/>
                            </g>
                        </svg>
                    </button>
                </div>
            </div>
        </header>
    <div className="space-sticky"></div>
    </>
    )
}
