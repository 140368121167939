import 'font-awesome/css/font-awesome.min.css';
import Toast from "react-bootstrap/Toast";

export const Footer = () => {

    const toastShow = () => {
        var toastElList = [].slice.call(document.querySelectorAll('#liveToastOne'))
        var toastList = toastElList.map(function (toastEl) {
            // @ts-ignore
            return new Toast(toastEl)
        });
        toastList.forEach(toast => toast.show());
    };

    return(
        <footer className="un-bottom-navigation filter-blur">
            <div className="em_body_navigation border-0 -active-links">
                <div className="item_link">
                    <a href="/" className="btn btn_navLink" aria-label="btnNavigation">
                        <div className="icon_current">
                            <i className="ri-home-5-line"></i>
                        </div>
                        <div className="icon_active">
                            <i className="ri-home-5-fill"></i>
                        </div>
                    </a>
                </div>
                <div className="item_link">
                    <a href="page-search-random.html" className="btn btn_navLink" aria-label="btnNavigation">
                        <div className="icon_current">
                            <i className="ri-search-2-line"></i>
                        </div>
                        <div className="icon_active">
                            <i className="ri-search-2-fill"></i>
                        </div>
                    </a>
                </div>
                <div className="item_link">
                    <button type="button" name="uploadItem"
                            className="btn btn_navLink without_active" data-bs-toggle="modal"
                            data-bs-target="#mdllUploadItem"
                            aria-label="btnNavigation">
                        <div className="btn btnCircle_default">
                            <i className="ri-add-line"></i>
                        </div>
                    </button>
                </div>
                <div className="item_link">
                    <a href="/favourit" className="btn btn_navLink" aria-label="btnNavigation">
                        <div className="icon_current">
                            <i className="ri-heart-3-line"></i>
                        </div>
                        <div className="icon_active">
                            <i className="ri-heart-3-fill"></i>
                        </div>
                    </a>
                </div>
                <div className="item_link">
                    {/*<button type="button" name="sidebarMenu" aria-label="sidebarMenu" className="btn"*/}
                    {/*        data-bs-toggle="modal" data-bs-target="#mdllSidebar-connected">*/}
                    {/*    <img className="img-avatar" src={"assets/images/avatar/profile.jpg"} alt=""/>*/}



                    <button
                       className="btn btn_navLink"
                       aria-label="btnNavigation"
                       onClick={toastShow}
                    >
                        <div className="icon_current">
                            <i className="ri-user-4-line"></i>
                        </div>
                        <div className="icon_active">
                            <img className="img-avatar" src={"assets/images/avatar/profile.jpg"} alt=""/>
                            <i className="ri-user-4-fill"></i>
                        </div>
                    </button>
                </div>
            </div>
        </footer>
    )
}