import DarkMode from "../DarkMode/DarkMode";

export const SideBarAdmin = () => {
    return(

        <div className="modal sidebarMenu -left fade" id="mdllSidebar-connected" tabIndex={-1}
             aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header d-block pb-1">

                        <div className="un-user-profile">
                            <div className="image_user">
                                <picture>
                                    {/*<source srcSet="/assets/images/avatar/11.webp" type="image/webp"/>*/}
                                        <img src="/assets/images/avatar/profile.jpg" alt="avatar" />
                                </picture>
                            </div>
                            <div className="text-user">
                                <h3>Ерёмин Владислав</h3>
                                {/*<p>0xe3oowe0b88...E162</p>*/}
                            </div>
                        </div>

                        <button type="button" className="btn btnClose" data-bs-dismiss="modal" aria-label="Close">
                            <i className="ri-close-fill"></i>
                        </button>

                        <div className="cover-balance">
                            <div className="un-balance">
                                <div className="content-balance">
                                    <div className="head-balance">
                                        <h4>TON кошелек</h4>
                                        <button className="btn link-addBalance" data-bs-toggle="modal"
                                           data-bs-dismiss="modal"
                                           data-bs-target="#mdllAddETH">
                                            <i className="ri-add-fill"></i>
                                        </button>
                                    </div>
                                    <p className="no-balance">36 050 TON</p>
                                </div>
                            </div>
                            {/*<button type="button" className="btn btn-sm-size bg-white text-dark rounded-pill"*/}
                            {/*        data-bs-toggle="modal" data-bs-dismiss="modal" data-bs-target="#mdllUploadItem">*/}
                            {/*    Create*/}
                            {/*</button>*/}
                        </div>


                    </div>
                    <div className="modal-body">
                        <ul className="nav flex-column -active-links">
                            <li className="nav-item">
                                <a className="nav-link" href="/homepage">
                                    <div className="icon_current">
                                        <i className="ri-compass-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-compass-fill"></i>
                                    </div>
                                    <span className="title_link">Исследовать</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/domains">
                                    <div className="icon_current">
                                        <i className="ri-home-5-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-home-5-fill"></i>
                                    </div>
                                    <span className="title_link">Домены</span>

                                    <span className="xs-badge">8</span>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/services">
                                    <div className="icon_current">
                                        <i className="ri-pages-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-pages-fill"></i>
                                    </div>
                                    <span className="title_link">Сервисы</span>

                                    <div className="badge-circle">
                                        <span className="doted_item"></span>
                                    </div>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="/messages">
                                    <div className="icon_current">
                                        <i className="ri-layout-2-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-layout-2-fill"></i>
                                    </div>
                                    <span className="title_link">Сообщения</span>

                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="/profile">
                                    <div className="icon_current">
                                        <i className="ri-layout-2-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-layout-2-fill"></i>
                                    </div>
                                    <span className="title_link">Профиль</span>

                                </a>
                            </li>

                            {/*<label className="title__label">other</label>*/}

                            <li className="nav-item">
                                <a className="nav-link" href="/page-help">
                                    <div className="icon_current">
                                        <i className="ri-questionnaire-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-questionnaire-fill"></i>
                                    </div>
                                    <span className="title_link">Клуб</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link" href="/page-about">
                                    <div className="icon_current">
                                        <i className="ri-file-info-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-file-info-fill"></i>
                                    </div>
                                    <span className="title_link">Поддержка</span>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a href="/" className="nav-link">
                                    <div className="icon_current">
                                        <i className="ri-logout-box-r-line"></i>
                                    </div>
                                    <div className="icon_active">
                                        <i className="ri-logout-box-r-fill"></i>
                                    </div>
                                    <span className="title_link">Выход</span>
                                </a>
                            </li>

                        </ul>
                    </div>

                    <div className="modal-footer">
                        <div className="em_darkMode_menu">
                            <DarkMode/>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    )
}