export const BestSeller = () => {
    return (
        <section className="margin-t-20 unList-bestSeller">
            {/*{!--un - title -default --}*/}
            <div className="un-title-default">
                <div className="text">
                    <h2>Продавцы</h2>
                    <p>Best seller of this week's NFTs</p>
                </div>
                <div className="un-block-right">
                    <a href="/best-seller" className="icon-back" aria-label="iconBtn">
                        <i className="ri-arrow-drop-right-line"></i>
                    </a>
                </div>
            </div>

            <div className="content-list-sellers">
                <ul className="nav flex-column">
                    <li className="nav-item">
                        <div className="nav-link item-user-seller">
                            <a href="/creator-profile" className="item-user-img">
                                <picture>
                                    <source srcSet={"assets/images/avatar/profile.jpg"} type="image/webp"/>
                                    <img className="avt-img" src={"assets/images/avatar/profile.jpg"} alt=""/>
                                </picture>
                                <div className="txt-user">
                                    <h5>DOMAIN<span>.RU</span></h5>
                                    <p>Eremin  Vladislav <i className="ri-checkbox-circle-fill"></i></p>
                                </div>
                            </a>
                            <div className="other-option">
                                <button type="button" className="btn btn-box-check">
                                    <input type="checkbox"/>
                                    <div className="icon-box">
                                        <i className="ri-add-fill"></i>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link item-user-seller">
                            <a href="/creator-profile" className="item-user-img">
                                <picture>
                                    <source srcSet={"assets/images/avatar/profile.jpg"} type="image/webp"/>
                                    <img className="avt-img" src={"assets/images/avatar/profile.jpg"} alt=""/>
                                </picture>
                                <div className="txt-user">
                                    <h5>7.2 <span>ETH</span></h5>
                                    <p>Doris Logue <i className="ri-checkbox-circle-fill"></i></p>
                                </div>
                            </a>
                            <div className="other-option">
                                <button type="button" className="btn btn-box-check">
                                    <input type="checkbox" checked/>
                                    <div className="icon-box">
                                        <i className="ri-add-fill"></i>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link item-user-seller">
                            <a href="/creator-profile" className="item-user-img">
                                <picture>
                                    <source srcSet={"assets/images/avatar/profile.jpg"} type="image/webp"/>
                                    <img className="avt-img" src={"assets/images/avatar/profile.jpg"} alt=""/>
                                </picture>
                                <div className="txt-user">
                                    <h5>6.8 <span>ETH</span></h5>
                                    <p>James White <i className="ri-checkbox-circle-fill"></i></p>
                                </div>
                            </a>
                            <div className="other-option">
                                <button type="button" className="btn btn-box-check">
                                    <input type="checkbox"/>
                                    <div className="icon-box">
                                        <i className="ri-add-fill"></i>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </li>
                    <li className="nav-item">
                        <div className="nav-link item-user-seller">
                            <a href="/creator-profile" className="item-user-img">
                                <picture>
                                    <source srcSet={"assets/images/avatar/profile.jpg"} type="image/webp"/>
                                    <img className="avt-img" src={"assets/images/avatar/profile.jpg"} alt=""/>
                                </picture>
                                <div className="txt-user">
                                    <h5>5.1 <span>ETH</span></h5>
                                    <p>Vladislav Eremin <i className="ri-checkbox-circle-fill"></i></p>
                                </div>
                            </a>
                            <div className="other-option">
                                <button type="button" className="btn btn-box-check">
                                    <input type="checkbox"/>
                                    <div className="icon-box">
                                        <i className="ri-add-fill"></i>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    )
}